import { fromJS, Map } from "immutable";

import logger from "Libs/logger";

const ADD_SSHKEY_START = "app/users/add_sshkey_start";
const ADD_SSHKEY_SUCCESS = "app/users/add_sshkey_success";
const ADD_SSHKEY_FAILURE = "app/users/add_sshkey_failure";
const ADD_SSHKEY_CANCEL = "app/users/add_sshkey_cancel";

const DELETE_SSHKEY_START = "app/users/delete_sshkey_start";
const DELETE_SSHKEY_SUCCESS = "app/users/delete_sshkey_success";
const DELETE_SSHKEY_FAILURE = "app/users/delete_sshkey_failure";

const LOAD_SSHKEYS_START = "app/users/load_sshkeys_start";
const LOAD_SSHKEYS_SUCCESS = "app/users/load_sshkeys_success";
const LOAD_SSHKEYS_FAILURE = "app/users/load_sshkeys_failure";

const EDIT_LINE = "app/users/edit_line";

export const editLine = index => ({
  type: EDIT_LINE,
  payload: index
});

export const cancelAddSshKey = () => ({ type: ADD_SSHKEY_CANCEL });

export function addSshKey(ssh) {
  return async dispatch => {
    dispatch({ type: ADD_SSHKEY_START });
    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const sshKey = await client.addSshKey(ssh.value, ssh.title);

      dispatch({ type: ADD_SSHKEY_SUCCESS, payload: sshKey });
    } catch (err) {
      logger(err, {
        action: "user_add_sshkey"
      });
      dispatch({ type: ADD_SSHKEY_FAILURE, error: true, payload: err });
    }
  };
}

export const loadSshKeys = () => async dispatch => {
  dispatch({ type: LOAD_SSHKEYS_START });
  try {
    const platformLib = await import("Libs/platform");
    const client = platformLib.default;

    const sshKeys = await client.getSshKeys();

    dispatch({ type: LOAD_SSHKEYS_SUCCESS, payload: sshKeys });
  } catch (err) {
    logger(err, {
      action: "user_load_sshkey"
    });
    dispatch({ type: LOAD_SSHKEYS_FAILURE, error: true, payload: err });
  }
};

export const deleteSshKey = id => async (dispatch, getState) => {
  dispatch({ type: DELETE_SSHKEY_START });
  try {
    const sshKey = getState().userSshKey.getIn(["data", id]);
    if (!sshKey) {
      throw Error("Ssh key not found");
    }

    await sshKey.delete();

    dispatch({ type: DELETE_SSHKEY_SUCCESS, payload: sshKey });
  } catch (err) {
    logger(err, {
      action: "user_delete_sshkey"
    });
    dispatch({ type: DELETE_SSHKEY_FAILURE, error: true, payload: err });
  }
};

export default function organizationSshKeysReducer(state = new Map(), action) {
  switch (action.type) {
    case LOAD_SSHKEYS_START:
    case ADD_SSHKEY_START:
    case DELETE_SSHKEY_START:
      return state.set("loading", true);
    case EDIT_LINE:
      return state.set("editedLine", action.payload);
    case ADD_SSHKEY_CANCEL:
      return state.set("editedLine", false);
    case ADD_SSHKEY_SUCCESS:
      return state
        .setIn(["data", action.payload.key_id], fromJS(action.payload))
        .set("editedLine", false)
        .set("loading", false);
    case DELETE_SSHKEY_SUCCESS:
      return state
        .deleteIn(["data", action.payload.key_id])
        .set("loading", false);
    case LOAD_SSHKEYS_SUCCESS:
      return action.payload
        .reduce((sshKeys, sshKey) => {
          return sshKeys.setIn(["data", sshKey.key_id], fromJS(sshKey));
        }, state)
        .set("loading", false);
    case ADD_SSHKEY_FAILURE:
    case DELETE_SSHKEY_FAILURE:
    case LOAD_SSHKEYS_FAILURE:
      return state.set("error", action.payload).set("loading", false);
    default:
      return state;
  }
}
